/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'mailbox-flag': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.5 8.5V3.707l.854-.853A.5.5 0 0011.5 2.5v-2A.5.5 0 0011 0H9.5a.5.5 0 00-.5.5v8zM5 7c0 .334-.164.264-.415.157C4.42 7.087 4.218 7 4 7s-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 012 0"/><path pid="1" d="M4 3h4v1H6.646A4 4 0 018 7v6h7V7a3 3 0 00-3-3V3a4 4 0 014 4v6a1 1 0 01-1 1H1a1 1 0 01-1-1V7a4 4 0 014-4m0 1a3 3 0 00-3 3v6h6V7a3 3 0 00-3-3"/>',
    },
});
